<template lang="pug">
div.content-header
  div.content-header-body(v-if="pageTitle || code")
    h1(v-if="pageTitle", v-html="pageTitle")
    div.mt-4(v-if="code")
      strong.block.font-medium.text-supplement.text-xs.uppercase Internal code(s):
      span.block.mt-1 {{ code.join(', ') }}
  div.content-header-controls
    div.content-header-controls-item.content-header-controls-item-change-template(v-if="multiTemplate")
      ToggleSwitch.rounded-full(v-model="contentTemplate", false-value="default", true-value="card", @change="changeTemplate")
        template(v-slot:option1): Icon(icon="list")
        template(v-slot:option2): Icon(icon="th-large")
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    pageTitle: {
      type: String
    },
    itemLabel: {
      type: String,
      default: "results"
    },
    multiTemplate: {
      type: Boolean,
      default: true
    },
    code: {
      type: Array
    }
  },
  data: () => ({
    contentTemplate: ""
  }),
  created() {
    this.contentTemplate = this.template
  },
  computed: {
    ...mapGetters({
      template: "content/template"
    }),
    template() {
      return this.$store.getters["content/template"] == "card" ? "card" : "default"
    }
  },
  methods: {
    changeTemplate() {
      if (typeof this.contentTemplate == "string") {
        this.$store.dispatch("content/changeTemplate", this.contentTemplate)
      }
    }
  }
}
</script>

<style>
.content-header {
  @apply mb-10 flex items-end justify-between;

  &-controls {
    @apply -mx-2 flex items-end;

    &-item {
      @apply mx-2;

      &.content-header-controls-item-change-template {
        @apply hidden;
      }
    }

    &-label {
      @apply hidden;
    }

    .form-inputgroup {
      @apply mb-0;

      &-label {
        @apply mb-1;
      }
    }

    .form-control-select {
      @apply border-none bg-transparent;

      .form-control-input {
        @apply cursor-pointer bg-transparent p-0 font-bold text-primary-default transition-colors;

        &:hover {
          @apply text-primary-hover;
        }
      }

      .form-control-append {
        @apply ml-2 mr-0;
      }
    }
  }
}

@screen md {
  .content-header {
    &-controls {
      &-item {
        &.content-header-controls-item-change-template {
          @apply inline-flex;

          .toggleswitch {
            .toggleswitch-slider {
              @apply bg-gray-300;

              &-option {
                @apply p-4 py-3;
              }
            }

            &.is-checked .toggleswitch-slider {
              @apply bg-gray-300 text-paragraph;
            }
          }
        }
      }

      &-label {
        @apply block;
      }
    }
  }
}
</style>
