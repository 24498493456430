<template>
  <Block
    type="unknown"
    icon="block-unknown"
    :id="9999999999"
    :collections="[]"
    :show-controls="false"
    :searchable="false"
  >
    <p class="mt-2 text-supplement">Uknown Block</p>
  </Block>
</template>

<script>
import Block from "./Block.vue"

export default {
  components: {
    Block
  }
}
</script>
