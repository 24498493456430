<template>
  <div class="content-items" :class="templateClass">
    <component
      v-for="item in blocks"
      :key="item.id"
      :is="getComponent(item.block_type)"
      v-bind="item"
      :show-controls="showControls"
    />
  </div>
</template>

<script>
import ContentBlock from "../Blocks/ContentBlock"
import CTABlock from "../Blocks/CTABlock"
import FormBlock from "../Blocks/FormBlock"
import HeadingBlock from "../Blocks/HeadingBlock"
import ImageBlock from "../Blocks/ImageBlock"
import ItineraryBlock from "../Blocks/ItineraryBlock"
import NoteBlock from "../Blocks/NoteBlock"
import TextBlock from "../Blocks/TextBlock"
import UnknownBlock from "../Blocks/UnknownBlock"

export default {
  watch: {
    items(value) {
      this.blocks = value
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    multiTemplate: {
      type: Boolean,
      default: true
    },
    showControls: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    blocks: []
  }),
  computed: {
    template() {
      return this.$store.getters["content/template"] == "card" ? "card" : "default"
    },
    templateClass() {
      if (!this.multiTemplate) {
        return "template-default"
      }

      return `template-${this.template}`
    }
  },
  created() {
    this.blocks = this.items
  },
  methods: {
    getComponent(type) {
      const componentMap = new Map([
        ["content", ContentBlock],
        ["cta", CTABlock],
        ["form", FormBlock],
        ["heading", HeadingBlock],
        ["image", ImageBlock],
        ["itinerary", ItineraryBlock],
        ["note", NoteBlock],
        ["text", TextBlock]
      ])

      return componentMap.get(type) || UnknownBlock
    }
  }
}
</script>

<style>
.content {
  &-controls {
    &-label {
      @apply mb-2 block text-xs font-semibold uppercase text-gray-500;
    }
  }

  &-items {
    @apply mt-6 space-y-3;

    .ag-block {
      @apply flex space-x-8 rounded-lg border-l-4 bg-white p-8 shadow;

      &__icon {
        @apply flex h-10 w-10 items-center rounded-md text-2xl md:justify-center;
      }

      &--content {
        @apply border-blocks-content;

        .ag-block__icon {
          @apply text-blocks-content;
        }
      }

      &--cta {
        @apply border-blocks-cta;

        .ag-block__icon {
          @apply text-blocks-cta;
        }
      }

      &--form {
        @apply border-blocks-form;

        .ag-block__icon {
          @apply text-blocks-form;
        }
      }

      &--heading {
        @apply border-blocks-heading;

        .ag-block__icon {
          @apply text-blocks-heading;
        }
      }

      &--image {
        @apply border-blocks-image;

        .ag-block__icon {
          @apply text-blocks-image;
        }
      }

      &--itinerary {
        @apply border-blocks-itinerary;

        .ag-block__icon {
          @apply text-blocks-itinerary;
        }
      }

      &--note {
        @apply border-blocks-note;

        .ag-block__icon {
          @apply text-blocks-note;
        }
      }

      &--text {
        @apply border-blocks-text;

        .ag-block__icon {
          @apply text-blocks-text;
        }
      }
    }
  }
}

@screen md {
  .content {
    &-items {
      &.template-card {
        @apply grid grid-cols-2 gap-6;
      }
    }
  }
}
</style>
