<template>
  <Block
    type="image"
    icon="block-image"
    :id="id"
    :searchable="searchable"
    :collections="collections"
    :show-controls="showControls"
  >
    <div class="flex space-x-6">
      <div class="flex-shrink-0">
        <a v-if="url" :href="url">
          <img :src="image.thumb_url" class="h-12 w-16 rounded-md object-cover" />
        </a>
        <img v-else :src="image.thumb_url" class="h-12 w-16 rounded-md object-cover" />
      </div>
      <div>
        <h2 class="ag-block__title">
          <a v-if="url" :href="url" target="_blank" v-html="$markdown(title)" />
          <span v-else v-html="$markdown(title)" />
        </h2>
        <p v-html="$markdown(synopsis)" />
      </div>
    </div>
  </Block>
</template>

<script>
import Block from "./Block.vue"

export default {
  components: {
    Block
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String
    },
    synopsis: {
      type: String
    },
    image: {
      type: Object,
      required: true
    },
    url: {
      type: String
    },
    searchable: {
      type: Boolean,
      required: true
    },
    has_customisations: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Array,
      default: () => []
    },
    showControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>
