<template>
  <Block
    type="text"
    icon="block-text"
    :id="id"
    :collections="collections"
    :show-controls="showControls"
    :searchable="searchable"
  >
    <div v-html="$markdown(synopsis)" />
  </Block>
</template>

<script>
import Block from "./Block.vue"

export default {
  components: {
    Block
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    synopsis: {
      type: String,
      required: true
    },
    searchable: {
      type: Boolean,
      required: true
    },
    collections: {
      type: Array,
      default: () => []
    },
    showControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>
