<template>
  <Block
    type="itinerary"
    icon="block-itinerary"
    :id="id"
    :collections="collections"
    :show-controls="showControls"
    :searchable="searchable"
  >
    <div class="flex space-x-6">
      <img v-if="featured_image" :src="featured_image.thumb_url" class="h-12 w-16 rounded-md object-cover" />
      <div>
        <h2 v-if="title" class="ag-block__title mt-1">
          <a v-if="url" :href="url" target="_blank" v-html="$markdown(title)" />
          <span v-else v-html="$markdown(title)" />
        </h2>
        <p v-else-if="synopsis" v-html="$markdown(synopsis)" />
        <p v-else class="mt-2 text-supplement">-</p>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from "./Block.vue"

export default {
  components: {
    Block
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String
    },
    synopsis: {
      type: String,
      required: true
    },
    featured_image: {
      type: Object
    },
    url: {
      type: String
    },
    searchable: {
      type: Boolean,
      required: true
    },
    collections: {
      type: Array,
      default: () => []
    },
    showControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>
