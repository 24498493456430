<template lang="pug">
Block(type="note", icon="block-note", :id="id", :collections="collections" :show-controls="showControls" :searchable="searchable")
  div.flex.space-x-6
    img(v-if="featured_image" :src="featured_image.thumb_url" class="h-12 w-16 rounded-md object-cover")
    div
      h2.ag-block__title
        a(v-if="url", :href="url", target="_blank", v-html="$markdown(title)")
        span(v-else, v-html="$markdown(title)")
      p(v-html="$markdown(synopsis)")
</template>

<script>
import Block from "./Block.vue"

export default {
  components: {
    Block
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String
    },
    synopsis: {
      type: String,
      required: true
    },
    featured_image: {
      type: Object
    },
    url: {
      type: String
    },
    searchable: {
      type: Boolean,
      required: true
    },
    collections: {
      type: Array,
      default: () => []
    },
    showControls: {
      type: Boolean,
      default: false
    }
  }
}
</script>
